import axios from "axios";
/* eslint-disable */
const state = {};
const mutations = {};
const action = {
  sendContato: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios
        .post(`https://mbd.ddns.net:3333/contato`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
    return promise;
  },
};
export default { state, mutations, action };

import { createApp } from "vue";
import App from "./App.vue";
import { MotionPlugin } from "@vueuse/motion";
import { createStore } from "vuex";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import swal from "sweetalert2";
window.Swal = swal;

import Store from "./js/Store.js";

const store = createStore({
  state: Store.state,
  mutations: Store.mutations,
  actions: Store.action,
});

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(store);

const Toast = swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", swal.stopTimer);
    toast.addEventListener("mouseleave", swal.resumeTimer);
  },
});

app.config.globalProperties.$toast = (status, msg) =>
  Toast.fire({
    icon: status || "success",
    title: msg || "Email encaminhado com sucesso!",
  });
app.use(MotionPlugin);
app.mount("#app");

<template>
    <div>
        <h2 class="title">Como posso comprar ou renovar meu <span>certificado</span>?</h2>
    <div class="tipsContainer">
        <div class="tips" v-for="(item) in tips" :key="item.id">
            <h2>{{item.num}}</h2>
            <p>{{item.desc}}</p>
        </div>
        </div>
        <div class="btnAgendar">
            <button><a :href="contato" target="_blank">Quero agendar</a></button>
        </div>
    </div>
</template>

<script>
export default{
    name:'ComprarCertificado',

    data(){
        return{
            tips:[
                {id:1, num:'1', desc:'Acesse a loja ou entre em contato para escolher seu certificado.'},
                {id:2, num:'2', desc:'Realize o pagamento com cartão ou PIX.'},
                {id:3, num:'3', desc:'Agende sua emissao no melhor horário e local, não esqueça de ter a documentação obrigatoria no dia agendado.'},

            ],
            
            contato:'https://api.whatsapp.com/send?phone=5512982295756',

        }
    }
}
</script>

<style scoped>
.title{
    text-align: center;
    margin: 15px;
    font-size: 2em;

}
.title span{
    color:#7C489B;
    font-weight: bold;
}
a{
    text-decoration: none;
    color: #fff;
}

.tipsContainer{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    height:300px ;
}

.tips{
    width: 35%;
    text-align: center;
}
.tips h2{
width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #7C489B;
  color: white;
  text-align: center;
  font-size: 35px;
  line-height: 80px;
  margin: 0 auto;
  justify-items: center;
}

.btnAgendar{
 margin-top:25px;
 display: flex;
 justify-content: center;
 text-decoration: none;
    
}

.btnAgendar button{
  border: solid 1px #ff851b;
    color: #fff;
    background-color: #ff851b;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 350px;
}

.btnAgendar button:hover{
    border: solid 1px #ff851b;
    color: #fff;
    background-color: #f0770d;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 350px;
}

@media only screen and (max-width: 869px)  {
    .tipsContainer{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.title{
    margin-bottom: 25%;
    font-size: 1.8em;
}

.tips{
    width: 100%;
    text-align: center;
}
.tips h2{
width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #7C489B;
  color: white;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  margin: 0 auto;
}

button{
    margin-top:10%
}
}
</style>
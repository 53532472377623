<template>
        <div v-motion-slide-visible-right>
            <div class="container">
                <h2>Quais os tipos de <span>certificados digital</span></h2>

                <div class="CardContainer">
                    <div v-for="(item) in Tipos" :key="item.id" class="card">
                        <h3>{{item.name}}</h3>
                        <p>{{item.desc}}</p>
                    </div>
                </div>

                 <div class="btnAgendar">
                    <button><a :href="contato" target="_blank">Quero agendar</a></button>
                </div>
            </div>
        </div>
</template>
<script>
    export default{
        name:'TiposCertificado',
        data(){
            return{
                Tipos:[
                    {id:1, name:'e-CNPJ', desc:'Certificado digital para empresas. É a solução destinada a pessoas jurídicas e atrelado ao CNPJ. A identidade digital possibilita a identificação da sua empresa no meio eletrônico, permitindo a realização de diferentes serviços.'},
                    {id:2, name:'e-CPF', desc:'É a solução destinada a pessoas físicas e se comporta como se fosse a seu CPF em versão digital. O e-CPF te identifica no meio eletrônico, permitindo a realização de diferentes serviços.'},
                    {id:3, name:'BIRD ID', desc:'Seu celular está sempre com você, e o certificado digital vai junto.Com o e-CPF na nuvem, você tem acesso ao certificado digital no seu smartphone. É simples e leve sempre com você'},

                ],

                contato:'https://api.whatsapp.com/send?phone=5512982295756',

            }
        }
    }
</script>
<style scoped>
.container{
    text-align: left;
    margin-top: 40px;

}
.container h2{
    font-size: 2.2em;
    text-align: center;
    margin-bottom: 5%;

}
.container h3{
    text-align: center;
}
.container span{
    color:#7C489B;
    font-weight: bold;
    text-align: center;
}

.CardContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
}
.card{
    width: 250px;
    margin-right: 25px;
    margin-bottom: 5%;
    border: none #292a2c;
    padding: 3%;
    border-radius: 14px;
    transition: all 0.3s;
    -webkit-transition: width 1s;
    transition: width 1s;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.card:hover{
        transform: scale(1.1);

}

.btnAgendar{
 margin-top:25px;
 display: flex;
 justify-content: center;
    
}
a{
    text-decoration: none;
    color: #fff;
}

.btnAgendar button{
  border: solid 1px #ff851b;
    color: #fff;
    background-color: #ff851b;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 350px;
}
.btnAgendar:hover button{
    border: solid 1px #ff851b;
    color: #fff;
    background-color: #f0770d;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 350px;
}

@media only screen and (max-width: 769px)  {
    .CardContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
}

.container h2{
    text-align: center;
    font-size: 2em;
   

}
.card{
    width: 90%;
    margin: 7px;
    border: none #292a2c;
    border-radius: 14px;
    -webkit-transition: width 1s;
    transition: width 1s;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}
}
</style>
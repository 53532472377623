<template>
  <div class="root">
<a @click="isOpen = true">Termos</a>
<teleport to="body">
<Transition name="modal">
<div class="modal" v-if="isOpen">
    <div class="modal_container">
        <div class="header_modal">
            <div class="content1">
                <img :src="logo" class="logo" alt="">
            </div>

            <div class="content2">
                <h1>Divulgação das Práticas de Negócios</h1>
            </div>
        </div>

        <div class="info">
            <p>Esta Divulgação das Práticas de Negócios (DPN) descreve as práticas e os procedimentos comerciais utilizados pela AR MBD, integrante da Infraestrutura de Chaves Públicas Brasileira (ICP-Brasil).</p>
            <p>Esta DPN está alinhada à Declaração de Práticas de Certificação (DPC), às Políticas de certificado (PC) e à Política de Segurança (PS) da Autoridade Certificadora AC AR MBD RFB, e aos Princípios e Critérios WebTrust para AR (WebTrust Principles and Criteria for Registration Autorities).</p>
            <p>A AR MBD mantém todas as informações da sua DPN sempre atualizadas.</p>

            <div class="buttons">
                <button class="btn1"><a :href="termos" target="_blank">Ver DPN - AC AR MBD CD V.01</a></button>

                <button class="btn2" @click="isOpen = false">Fechar</button>
            </div>
        </div>
    </div>

</div>
</Transition>
</teleport>
  </div>
</template>

<script setup>
import {ref} from 'vue'
const isOpen=ref(false)


</script>

<script>
export default {
name:'modalVue',

data(){
    return{
        logo:'/images/logo.png',
        termos:'/images/DPN_AR_MBD.pdf',
    }
}
}

</script>

<style scoped>
p{
    
  color: #292a2c;
}
.root{
    position: relative;

}

.root a:hover{
    color: #9370DB;
}

.modal{
    position:absolute;
    top: 0;
    left: 0;
    background-color: rgba(0.2,0.2,1,0.6);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal > div{
background-color: #fff;

}

.modal_container{
    padding: 35px;
    margin:20%;
    border-radius: 20px;
    height: 490px;
    position: fixed;

}

.modal-enter-active,
.modal-leave-active{
    transition: all 0.25s ease;
}

.modal-enter-from,
.modal-leave-to{
    opacity: 0;
    transform: scale(1.1);
}

.header_modal{
    display: flex;
    flex-direction: row;
    }



.content1{
    width: 60%;
}
.content1 img{
    width:280px;
}
.content2{
    width: 40%;
    color: #ff851b;
    text-align: right;
    font-weight: bold;
}

h1{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}


.info{
    height: 600px;
}

.info p {
    font-size: 1.1em;
    
}

.btn1{
    background-color: #0a84f0;
    color: #fff !important;
    border-radius: 7px;
    padding: 15px;
    border: none;
    margin-right: 10px;
    font-weight: bold;
    text-decoration: none !important;

}

.btn2{
    background-color: #0a84f0;
    color: #fff;
    border-radius: 7px;
    padding: 15px;
    border: none;
    margin-right: 10px;
    font-weight: bold;
}

a{
    text-decoration: none;
    color: #fff;

}

.root a{
    color:#292a2c;
}

.buttons{
    margin-top: 10%;
}


@media only screen and (max-width: 869px)  {
p{
    
  color: #292a2c;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}
.root{
    position: relative;

}

.modal{
    position:absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal > div{
background-color: #fff;

}

.modal_container{
    padding: 55px;
    margin:50%;
    border-radius: 20px;
    height: auto;

}

.header_modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    margin:0 auto;
    }



.content1{
    width: 100%;
}
.content1 img{
    width:380px;
}
.content2{
    width: 100%;
    color: #ff851b;
    text-align: center;
    font-weight: bold;
    font-size: 1em;
}


.info{
    height: auto;
}

.info p {
    font-size: 1em;
    width: 320px;
    margin: 0 auto;
    
}

.btn1{
    background-color: #0a84f0;
    color: #fff;
    border-radius: 7px;
    padding: 10px;
    border: none;
    margin-right: 5px;
    font-weight: bold;
    margin-left: 7%;
    margin-top: 5%;
}

.btn2{
    background-color: #0a84f0;
    color: #fff;
    border-radius: 7px;
    padding: 10px;
    border: none;
    margin-right: 10px;
    font-weight: bold;
    font-size: 1em;
}
}
</style>
<template>
    <div>
        <div class="container">

            <div class="icon">
                <img :src="icon" alt="">
            
            </div>

            <div class="texto" v-motion-slide-right>
                <h2>Benefícios de um certificado digital</h2>
                <p>O certificado digital é uma assinatura com validade jurídica que garante proteção as transações eletrônicas e outros serviços via internet, permitindo que pessoas e empresas se identifiquem e assinem digitalmente de qualquer lugar do mundo com mais segurança e agilidade.</p>
            </div>


        </div>
    </div>
</template>

<script>
export default{
    name:'BeneficiosVue',
    data(){
        return{
            icon:'./images/icon.png'
        }
    }
}
</script>

<style scoped>

body{
   margin: 0 auto !important;
   overflow-x:hidden;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7C489B;
  color: #fff;
  height: 350px;
  width: 100%;
  margin: 0 -9999rem;
  padding: 0.5rem 9999rem;
}

.container h2{
    font-size: 2em;
}
.icon{
    width: 30%;
    margin: 10px;
    margin: 0 auto;

}

.texto{
    width: 70%;
    margin: 0 auto;
}

.texto p{
    width:650px;
    text-align: justify;

}

@media only screen and (max-width: 869px)  {
.container{ 
    display: flex;
    flex-direction: column;
    width: 100%;
    color:#fff;
    text-align: center;
    background-color:#7C489B;
    margin: 0 auto;
    border-radius: 5px;
    padding: 10px;
    height: 650px; 
}
.icon{
    width: 100%;
    margin-top: 5% !important;
    text-align: center;
}
.texto{
    width: 90%;
    text-align: center;
    margin:0 auto;
}

.container h2{
    font-size: 1.8em;
}

.texto p{
    width: auto;
    text-align: center;

}

}

@media (min-width: 1920px) and (max-width: 2560px)  {

.icon{
    width: 35%;
    margin: 10px;
    margin: 0 auto;

}

.texto{
    margin: 0 auto;
}

.texto p{
    width:650px;

}
}
</style>

<template>
    <div>
        <div class="container" id="empresa">
            <h2 class="title">Conheça nossa empresa</h2> 

            <div class="content">
                <div class="texto">
                    <p>AR MBD é uma empresa responsável por validar o certificado digital, importante para representar e verificar a identidade de pessoas e empresas no meio eletrônico, proporcionando muitos benefícios.</p>
                </div>
                <div class="imagem">
                    <img :src="image" alt="">
                </div>

            </div>          
        </div>
          
    <div class="color" v-motion-slide-visible-once-left>
        <h2>Podemos fazer muito pela sua empresa, conte conosco!</h2>
        <div class="IconContainer">
            
            <div class="icons" v-for="(icon) in icons" :key="icon.id">
                <img :src="icon.img" class="iconImg" alt="">
                <p>{{icon.desc}}</p>
            </div>
        </div>
        </div>

        <div class="comoChegar">
            <div class="textoComoChegar">
                <h2>Como chegar</h2>
                <p>Para clientes com emissão presencial, estamos sempre contentes em recebe-lo.
Venha conferir nossas instalações.</p>
            </div>
            <div class="maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14718.402059833352!2d-45.1098332!3d-22.7430851!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ccc929a1a263d7%3A0x839f23a677cba906!2sMBD%20Inform%C3%A1tica!5e0!3m2!1spt-BR!2sbr!4v1687981379459!5m2!1spt-BR!2sbr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

        </div>
    </div>
</template>

<script>
export default{
    name:'EmpresaVue',

    data(){
        return{
            icons:[
                {id:1, name:'icon1',img:'/images/iconTempo.png', desc:'Seu certificado na hora'},
                {id:2, name:'icon2',img:'/images/corrida.png', desc:'Nossos agentes irão até você'},
                {id:3, name:'icon3',img:'/images/beneficio.png', desc:'Temos o melhor custo benefício'},
            ],
            image:'images/mbd.png'
        }
    }
}
</script>

<style scoped>

.container{
    margin-top: 45px !important;
    color: #000;
    background-color: #dcdcde;
    text-align: center;    
    width: 100%;
    height: 500px;
    padding: 1px;
    overflow: hidden;
    margin-bottom: 8% !important;
    margin: 0 -9999rem;
    padding: 0.5rem 9999rem;
}
.title{
    margin-top: 50px;
    font-size:2.2em
}

.content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
}
.texto{
    font-size: 20px;
    width: 45%;
    text-align: left;
    margin:5% ;
}
.imagem{
    width: 50%;
}

.imagem img{
    height: 320px;
    width: 550px;
}

.IconContainer{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    margin: 0 auto;
}

.icons{
   width: 35%;
   margin-top: 3%;
}

.icons p{
    font-size: 20px;
}

.iconImg{
    height: 80px;
    width: auto;
}
.color{
    color:#fff;
    text-align: center;
    background-color:#ff851b;
    width: 80%;
    margin: 0 auto;
    border-radius: 5px;
    padding: 10px;
    
}

.color h2{
    margin-top: 10px;
    font-size: 2.2em;
}

.comoChegar{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 5%;
}
.textoComoChegar{
    width: 45%;
    text-align: center;
    margin-right: 10px;
    margin-top:10%
    
   
}
.textoComoChegar h2{
    font-size: 2.2em;

}
.textoComoChegar p{
    font-size: 18px;
    text-align: left;
    margin: 0 auto;
    width: 60%;
}
.maps{
width: 50%;


}

@media only screen and (max-width: 869px)  {

    body{
   margin: none !important;
    width: 100% !important;
    overflow-x:hidden;
}
  .container{
    display: flex;
    flex-direction: column;
    height: auto;
    color:#000;
    text-align: center;
    background-color:#dcdcde;
    width: auto;
    margin: 0 auto;
    border-radius: 5px;
    padding: 10px;


 
   
}
.title{
    margin-top: 50px;
}

.content{
    display: flex;
    flex-direction: column;
   text-align: center;
   justify-content:center ;
}
.texto{
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin:5% ;
    width: 300px;
}
.imagem{
    width: 100%;
}

.imagem img {
    width: 100%;
    height: 250px;


}


.IconContainer{
    display: flex;
    flex-direction: column;
    text-align: center;
    
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    margin: 0 auto;
}

.icons{
   width: 100%;
   margin-top: 3%;
}

.iconImg{
    height: 40px;
    width: auto;
}

.color{
    color:#fff;
    text-align: center;
    background-color:#ff851b;
    width: auto;
    margin: 0 auto;
    border-radius: 5px;
}
.color h2{
    font-size: 1.8em;
    margin:6%;
}

.comoChegar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
}
.textoComoChegar{
    width: 100%;
    text-align: center;

}
.textoComoChegar p{
    text-align: center;
    width: 300px;
    margin-bottom: 15px;
}

.maps iframe{
max-width: 300%;

}
}
</style>
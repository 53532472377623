<template>
    <div>
        <div class="footer">
            <p>AR MBD Soluções Digitais - CNPJ 40.834.422/0001-87 - Copyright {{new Date().getFullYear()}}</p>
        </div>
    </div>
</template>


<script>
export default{
    name:'footerVue'
}
</script>

<style scoped>
body{
    background-color: #dcdcde;
    text-align: center;
}

.footer{
    width: 100%;
    text-align: center;
}
</style>

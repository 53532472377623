<template>
<div class="Main">
  <Header/>
  <FirstContent/>
  <Beneficios/>
  <ComprarCertificado/>
  <TiposCertificado/>
  <Empresa/>
  <Revenda/>
  <Contato/>
  <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import FirstContent from './components/FirstContent.vue'
import Beneficios from './components/Beneficios.vue'
import ComprarCertificado from './components/ComprarCertificado.vue'
import TiposCertificado from './components/TiposCertificado.vue'
import Empresa from './components/Empresa.vue'
import Revenda from './components/Revenda.vue'
import Contato from './components/Contato.vue'
import Footer from './components/Footer.vue'





export default {
  name: 'App',
  components: {
    Header,
    FirstContent,
    Beneficios,
    ComprarCertificado,
    TiposCertificado,
    Empresa,
    Revenda,
    Contato,
    Footer,
  },
  
}
</script>

<style>
#app > * {
    font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;

}
body{
   margin: none !important;
    width: 100%;
    overflow-x:hidden;
}
p{
  font-size: 18px;
}

html{
    scroll-behavior: smooth;

}

a{
  text-decoration: none;
}
p{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

button{
  cursor: pointer;
}


.Main{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}
@media only screen and (max-width: 869px)  {
body{
   margin: 0 auto !important;
    overflow-x:hidden !important;
    width: 100%;
}
.Main{
  width: 100%;
  overflow-x:hidden;
}

h2{
  font-size: 2em;
}



p{
          font-size: 18px;

}

}


</style>

<template>
    <div>
        <div class="container">
            <div class="text" v-motion-fade>
                <h1>Certificado digital. <span>Emissão e renovação</span> para você e sua empresa</h1>
                <p>Emita ou renove o certificado digital para você e para sua empresa de forma remota e em 5 minutos.</p>
                <button class="btnAgendar"><a :href="contato" target="_blank">Quero agendar</a></button>
            </div>

            <div class="Imagem" v-motion-slide-right>
                  <img :src='image' alt="">
            </div>



        </div>
    </div>
</template>

<script>
export default{
    name:'FirstContent',
    data(){
        return{
            image:'/images/img1.jpg',
            contato:'https://api.whatsapp.com/send?phone=5512982295756',
        }
    }
}

</script>

<style scoped>
body{
margin: 0;
}

.container{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 100% !important;
    overflow-x: hidden;
    

}

a{
    text-decoration: none;
    color:#7C489B;

}

.text{
    width: 50% !important;
    text-align: left;
    margin-top: 5%;

    
}
.text h1{
    font-size: 2.3em;
}

.text span{
    color:#7C489B;
}

.text p {
    color: #808080;
    font-size: 1.5em;
}


.btnAgendar{
    border: solid 1px #7C489B;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 350px;
}

.btnAgendar:hover{
    background-color: #7C489B;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 350px;   
}
.btnAgendar:hover a{
    color: #fff !important;
}

.Imagem{
    width: 40%;
    height: 100%;
    position:relative

}

.Imagem img{
    height: 500px;
    width: 100%;
    margin: 0 auto;
    display: inline-block;

}

@media only screen and (max-width: 769px)  {
  
.container{
    top:200px ;
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
    justify-content: center;
    width: 100% !important;
    

}

.text{
    width: 90% !important;
    text-align: center;
    margin: 10px;    
    font-size: 10px;
}
.text h1{
font-size:2.5em;
}

.text span{
    color:#7C489B;
}

.text p {
    color: #808080;
    font-size: 1.5em;
    text-align: center

}
.Imagem{
    width: 100%;
}

.Imagem img{
    height: auto;
    width: 100%;
}


.btnAgendar{
    border: solid 1px #7C489B;
    background: #fff;
    color: #7C489B;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 300px;
}
}
@media  (min-width: 1800px) and (max-width: 1800px) {

    .text{
        width: 50%;
        margin-top: 5% !important;
    }
      .Imagem{
    width: 35%;
    height: auto;
   

}

.Imagem img{
    width: 70%;
    margin: 0 auto;

}
}

@media (min-width: 1620px) and (max-width: 1920px)  {

 
.Imagem img{
    height: 500px;
    width: 100%;
    margin: 0 auto;
}
.text{
    width: 50%;
    margin-top: 5%;

}
}

@media (min-width: 2420px) and (max-width: 3760px)  {
    .Imagem{
    width: 40%;
    height: 100%;
}
.Imagem img{
    height: 500px;
    width: 80%;
    margin: 0 auto;
}
.text{
    width: 50%;
    margin-top: 5%;

}

}

@media (min-width: 1920px) and (max-width: 2760px)  {
.text{
    width: 50%;
    margin-top: 5%;

}
.Imagem{
    width: 40%;
    height: 100%;
}

.Imagem img{
    height: 500px;
    width: 80%;
    margin: 0 auto;
}
}

</style>
<template>
    <div id="contato">
        <h2 class="title">Venha conferir nossos produtos</h2>

        <div class="FormContainer">

           <form action="" @submit="EnviarForm($event)" >
            
                <div class="titleContato">
                        <h2>Contato</h2>
                </div>

                <div class="form-group">
                    <input type="text" id="nome" name="nome" v-model="form.nome" required placeholder="Insira seu nome">
                </div>

                <div class="form-group formDuplo">
                    <input type="email" id="email" name="email" v-model="form.email" required placeholder="Insira seu e-mail">

                    <input type="tel" id="telefone" name="telefone" v-model="form.telefone" v-mask="['(##) ####-####', '(##) #####-####']" required placeholder="Insira seu telefone">
                </div>


                <textarea name="msg" id="msg" cols="30" rows="10" v-model="form.mensagem" placeholder="Digite sua mensagem"></textarea>

                

                <button :disabled="loading" name="msg" type="submit"> {{ loading?'Enviando...':'Enviar' }} </button>
            </form>
        </div>
    </div>
</template>

<script>
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import swal from 'sweetalert2';
window.swal = swal;

import {mask} from 'vue-the-mask'


// import axios from 'axios';
export default {
        name:'ContatoVue',
        data(){
            return{
                loading: false,
                form:{
                    nome:'',
                    email:'',
                    telefone:'',
                    mensagem:'',
                }
            }
        },

          directives: {mask},

        methods:{
            EnviarForm(e){
                e.preventDefault();
                this.form.to = 'armbd@armbd.com.br'
                this.form.from = 'Contato AR MBD<suporte@mbd.com.br>'
                this.loading = true;
                this.$store.dispatch('sendContato', this.form)
                    .then(response => {this.$toast("success", response)}, this.LimparForm())
                    .catch(error => { this.$toast("error",error);})
                    .finally(() => { this.loading = false});
            },

            LimparForm(){
                 this.form.nome=''
                 this.form.email=''
                 this.form.telefone=''
                 this.form.mensagem=''                 
            },

           
        }
  }
</script>

<style scoped>

.title{
    margin-top: 12%;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
}


.titleContato h2{
    color: #fff;
    text-align: center;
    font-size: 2em;
}

.FormContainer{
    display: flex;
   background-color: #200526;

    height: 500px;
    width: 100%;
    justify-content: center;
    text-align: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    margin-top: 15px;
    margin: 0 -9999rem;
    padding: 0.5rem 9999rem;


}



.form-group {
    margin-bottom: 20px;
}

.formDuplo{
    display: flex;
    flex-direction: row;
}

label{
    display: block;
    margin-bottom: 5px;
    text-align: left;
    color: #808080;
}
input:focus{
    outline: none;
}

#nome{
    width: 1000px;
    height: 40px;
    border-radius: 7px;
}

#email{
    width: 50%;
    display: block;
    margin-bottom: 5px;
    text-align: left;
    color: #808080;
    margin-right: 15px;
    height: 40px;
    border-radius: 7px;
}

#telefone{
width: 50%;
height: 40px;
border-radius: 7px;
}

#msg{
display: block;
height: 150px;
border-radius: 7px;
max-height: 400px;
width: 100%;
max-width: 100%;
font-family: Poppins !important;

margin-bottom: 15px;
}

input[type="text"],
input[type="tel"],
input[type="email"] {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    width: 100%;
    font-size: 14px;
}

button{
    color: #fff;
    border-radius: 10px;
    padding: 7px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 300px;
}

button:disabled {
    cursor: not-allowed;
}

button:not(:disabled) {
    border: solid 1px #ff851b;
    background-color: #ff851b;
}


@media only screen and (max-width: 420px)  {
    .FormContainer{
    display: flex;
    background-color: red;
    height: 500px;
    justify-content: center;
    text-align: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    padding: 10px;

    


}
}

@media only screen and (max-width: 769px)  {
.title{
    font-size: 2.2em;
    font-weight: bold;
    text-align: center;
}

.titleContato{
   display: flex;
}
.titleContato h2{
    color: #fff;
    text-align: center;
    float: right;
}

.FormContainer{
    display: flex;
    background-color: #200526;
    height: 500px;
    justify-content: center;
    text-align: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    margin-top: 15px;
    width: auto;
    margin: 0 auto;
    border-radius: 5px;
    padding: 10px;

    


}



.form-group {
    margin-bottom: 20px;
}

.formDuplo{
    display: flex;
    flex-direction: column;
}

label{
    display: block;
    margin-bottom: 5px;
    text-align: left;
    color: #808080;
}
input:focus{
    outline: none;
}

#nome{
    width: 100%;
    height: 25px;
    border-radius: 7px;
}

#email{
    width: 100%;
    display: block;
    margin-bottom: 5px;
    text-align: left;
    color: #808080;
    margin-right: 15px;
    height: 25px;
    border-radius: 7px;
}

#telefone{
width: 100%;
height: 25px;
border-radius: 7px;
}

#msg{
display: block;
height: 150px;
border-radius: 7px;
max-height: 400px;
width: 100%;
margin-bottom: 15px;
}

input[type="text"],
input[type="tel"],
input[type="email"] {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    width: 100%;
    font-size: 14px;
}

button{
    background-color: #ff851b;
    padding: 7px;
    border-radius: 7px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    width: 260px;
    border: none;
}
}


</style>

<template>
    <div id="revenda">
        <h2 class="title">Seja revendedor</h2>
        <div class="container">
            <div class="texto" v-motion-slide-visible-left>
                <h2>Buscando novas oportunidades para agregar em seu negócio?</h2>
                <p>Faça parte de um dos mercados que mais crescem no Brasil e agregue ao portifolio de sua empresa a emissao de certificados!</p>
                <button><a href="#contato">Quero ser revenda</a></button>
            </div>
            <div class="imagem" v-motion-slide-visible-once-right>
                <img :src="image" class="imagem" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'RevendaVue',

    data(){
        return{
            image:'images/man.png'
        }
    }
}
</script>

<style scoped>

.title{
    color:#ff851b;

}
h2{
    text-align: center;
    font-size: 2em;

}

button{
    border: solid 1px #ff851b;
    color: #fff;
    background-color: #ff851b;
    border-radius: 10px;
    margin-top: 15%;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 350px;

}

button:hover{
    border: solid 1px #ff851b;
    color: #fff;
    background-color: #f0770d;
    border-radius: 10px;
    margin-top: 15%;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 350px;
}

a{
    text-decoration: none;
    color: #fff;
}
.container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.title{
    margin-top: 7%;
}
.texto{
    width: 50%;
    text-align: center;
    margin-top:-7%;
    margin-left: 5%;
    
}

.texto h2{
    margin-top: 15%;
    font-size: 2em;
}

.texto p{
    font-size: 1.3em;
    margin-bottom: 5%;
}

.imagem{
    width:50%;
    margin: 15px;
    margin-right:auto !important;
    border-top-left-radius: 160px;
    border-bottom-left-radius: 160px;
    margin-right: -12% !important;
}

.imagem img{

display: inline-block;
vertical-align: top;
width: 80%;
height: 100%;
bottom: auto;
}



@media only screen and (max-width: 869px)  {
h2{
    text-align: center;
    font-size: 2em;
    width: 100%;

}

button{
    border: solid 1px #ff851b;
    color: #fff;
    background-color: #ff851b;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    width: 300px;

}
.container{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
}
.texto{
    width: 90%;
    text-align: center;
}


.texto p{
    margin:0 auto;
    width: 300px;
    text-align: center !important;
        font-size: 18px;

}

/* .imagem{
    margin:0 auto;
    width: 100%;
    border-radius: 10px;
    background-color: #ff851b;
} */

.imagem{
    width:100%;
    margin-right:auto !important;
    border-top-left-radius: 160px;
    border-bottom-left-radius: 160px;
}

.imagem img{

display: inline-block;
vertical-align: top;
width: 100%;
height: 90% !important;
bottom: 0;
right: 0;
}





button{
    margin-top: 20px;
}
}
</style>
